import React from 'react';
import Autosuggest from 'react-autosuggest';
import { getProductTypes } from '../Recipes';

const getSuggestions = (value: string) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    const products = getProductTypes();
    return inputLength === 0 ? [] : products.filter((product) =>
        product.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
    )
};

const getSuggestionValue = (p: string) => p;

const renderSuggestion = (p: string) => (
    <span>
        {p}
    </span>
);

interface ProductSelectorProps {
    onSelected?: (p: string) => void;
    product?: string;
}

const ProductSelector: React.SFC<ProductSelectorProps> = (props) => {
    const [suggestions, setSuggestions] = React.useState<string[]>([]);
    const [value, setValue] = React.useState(props.product || '');
    const onChange = (_event: any, { newValue }: { newValue: any }) => {
        console.log('onChange', newValue);
        setValue(newValue);
    };
    const inputProps = {
        placeholder: 'Type a product',
        value,
        onChange,
    };

    const onSuggestionsFetchRequested = ({ value }: any) => {
        setSuggestions(getSuggestions(value));
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const onSuggestionSelected = (_ev: any, { suggestion }: { suggestion: string }) => {
        props.onSelected?.(suggestion);
    };

    return (<div>
        <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            onSuggestionSelected={onSuggestionSelected}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
        />
    </div>);
};

export default ProductSelector;
