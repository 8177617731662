import React from 'react';

interface KeyValueEditorProps {
    possibleKeys: string[];
    getValuesForKey: (key: string) => (string[] | undefined);
    title: string;
    keyLabel: string;
    valueLabel: string;
    onChanged: (key: string, value: string) => void;
    onRemoved: (key: string) => void;
    keyValues: {
        key: string;
        value: string
    }[]
}

const KeyValueEditor: React.SFC<KeyValueEditorProps> = (props) => {
    const [key, setKey] = React.useState(props.possibleKeys[0]);
    const initalValue = props.getValuesForKey(key) ? props.getValuesForKey(key)?.[0] : '';
    const [value, setValue] = React.useState(initalValue);
    const valueChanged = (e: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };
    const keyChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setKey(e.target.value);
        setValue(props.getValuesForKey(e.target.value)?.[0]);
    };
    const add = () => {
        props.onChanged(key, value || '');
    };
    const remove = (key: string) => {
        props.onRemoved(key);
    }
    return (
        <>
            <h2>{props.title}</h2>
            <select onChange={keyChanged}>
                {props.possibleKeys.map((key) => <option value={key}>{key}</option>)}
            </select>
            {key && props.getValuesForKey(key) === undefined && <input type="text" value={value} onChange={valueChanged} />}
            {key && props.getValuesForKey(key) &&
                <select onChange={valueChanged} value={value}>
                    {props.getValuesForKey(key)?.map((v) => <option value={v}>{v}</option>)}
                </select>
            }
            <button disabled={!key || !value} onClick={add}>add</button>
            <table className="table" style={{ width: '95%' }}>
                <thead>
                    <tr>
                        <th>{props.keyLabel}</th>
                        <th>{props.valueLabel}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {props.keyValues.map(({ key, value }) => (
                        <tr>
                            <td>{key}</td>
                            <td style={{ textAlign: 'right' }}>{value}</td>
                            <td style={{ textAlign: 'center' }}><button onClick={() => remove(key)}>Remove</button></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};

export default KeyValueEditor;
