import React from 'react';
import './App.css';
import {
    HashRouter as Router,
    Switch,
    Route,
    NavLink,
    Redirect
} from "react-router-dom";
import { SettingsContext, defaultSettings, SettingsIF } from './SettingsContext';
import Craft from './pages/Craft';
import Settings from './pages/Settings';
import List from './pages/List';
import SaveLoad from './pages/SaveLoad';
import Food from './pages/Food';
import { getVersion } from './Recipes';

function App() {
    const savedSettings: any = JSON.parse(localStorage.getItem('saves') || '{}');
    console.log('saved', savedSettings);
    let settingsToUse = savedSettings && savedSettings.autosave ? { ...defaultSettings, ...savedSettings.autosave } : defaultSettings;
    if (settingsToUse.hash !== defaultSettings.hash) {
        alert('Data files changed for this save, reset to default recommended');
    }
    const [settings, setSettings] = React.useState(settingsToUse);
    const setAndSaveSettings = (settings: SettingsIF) => {
        setSettings(settings);
        try {
            const saves: any = JSON.parse(localStorage.getItem('saves') || '{}');
            saves.autosave = settings;
            localStorage.setItem('saves', JSON.stringify(saves, null, 2));
        } catch (e) {
            console.log(e);
        }
    };
    return (
        <SettingsContext.Provider value={{ ...settings, setSettings: setAndSaveSettings }}>
            <Router>
                <nav>
                    <ul>
                        <li>
                            <NavLink activeClassName='active' to="/craft">Craft</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName='active' to="/list">List</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName='active' to="/settings">Settings</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName='active' to="/saveload">Save / Load</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName='active' to="/food">Food</NavLink>
                        </li>
                    </ul>
                    <div style={{ background: '#ccc', padding: '5px', fontSize: '12px' }}>
                        data version: <span style={{ fontWeight: 'bold' }}>{getVersion()}</span>
                    </div>
                </nav>

                <div className="app">
                    <Switch>
                        <Route path="/craft/:product?/:recipe?">
                            <Craft />
                        </Route>
                        <Route path="/settings">
                            <Settings />
                        </Route>
                        <Route path="/saveload">
                            <SaveLoad />
                        </Route>
                        <Route path="/List">
                            <List />
                        </Route>
                        <Route path="/Food">
                            <Food />
                        </Route>
                        <Route path="/">
                            <Redirect from="/" to="craft" />
                        </Route>
                    </Switch>
                </div>
            </Router >
        </SettingsContext.Provider >
    );
}

export default App;
