import recipes from './recipes.json';

interface Dictionary<T> {
    [key: string]: T;
}

export interface Root {
    recipes: Recipes,
    items: Items,
    foods: Food[],
    version: string
}

export interface Food {
    name: string;
    calories: number;
    nutrients: {
        carbs: number;
        fat: number;
        protein: number;
        vitamins: number;
    };
    bench: string;
    requiredSkill: {
        skill: string;
        level: string;
    };
}

export interface Items {
    [key: string]: Item;
}

export interface Item {
    name: string,
    tags: string[],
}

export interface Recipes {
    [key: string]: Recipe;
}

export interface Recipe {
    recipe: string,
    products: Product[],
    calories?: Calories,
    ingredients: Ingredient[],
    bench: string,
}

export interface Calories {
    amount: number;
    skill: string;
}

export interface Ingredient {
    amount: number,
    type: string,
    skill: string,
    mult: boolean,
    lavish: boolean
}

export interface Product {
    amount: number,
    type: string,
}

export function getVersion() {
    return (recipes as Root).version;
}

export function getRecipeSelections() {
    const productTypes = getProductTypes();
    const result = [];
    for (let pt of productTypes) {
        const recipes = getRecipesForProduct(pt);
        result.push({
            product: pt,
            recipes: recipes.map((r) => ({
                recipe: r.recipe,
                bench: r.bench
            })),
        });
    }
    return result.filter((r) => r.recipes.length > 1);
}

export function getTags(): Dictionary<string[]> {
    const result: Dictionary<string[]> = {};
    const items = (recipes as Root).items;
    for (let itemName of Object.keys(items)) {
        const item = items[itemName];
        for (let tag of item.tags) {
            result[tag] = result[tag] || [];
            result[tag].push(itemName);
        }
    }
    return result;
}

export function getProductTypes(): string[] {
    const products = new Set<string>();
    const rr = getRecipes();
    for (let recipeName of Object.keys(rr)) {
        const recipe = rr[recipeName];
        for (let product of recipe.products) {
            products.add(product.type);
        }
    }
    return Array.from(products).sort();
}

// export function getFoods(): string[] {
//     const products = new Set<string>();
//     const rr = getRecipes();
//     for (let recipeName of Object.keys(rr)) {
//         const recipe = rr[recipeName];
//         for (let product of recipe.products) {
//             products.add(product.type);
//         }
//     }
//     return Array.from(products).sort();
// }

export function getRecipesForProduct(name: string): Recipe[] {
    const result: Recipe[] = [];
    const rr = getRecipes();
    for (let recipeName of Object.keys(rr)) {
        for (let product of rr[recipeName].products) {
            if (product.type === name) {
                result.push(rr[recipeName]);
            }
        }
    }
    return result;
}

export function getDataHash(): number {
    // TODO: This is broken
    return hash(JSON.stringify(recipes));
};

function hash(s: string) {
    var hash = 0;
    if (s.length === 0) return hash;
    for (let i = 0; i < s.length; i++) {
        const char = s.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash = hash & hash;
    }
    return hash;
}


function getRecipes(): Recipes {
    return (recipes as Root).recipes;
};

export function getFoods(): Food[] {
    return (recipes as Root).foods;
};

function indent(i: number) {
    return ''.padStart(i * 2, ' ');
}

function calcAmount(level: number, ingredient: Ingredient, factor: number, log: string[], settings: Settings, bench: string) {
    const skillLevel = settings.skills.find((s) => s.skill === ingredient.skill)?.level || 0;
    const moduleLevel = settings.modules.find((m) => m.bench === bench)?.level || 0;
    let skillFactor = 1;
    if (ingredient.mult) {
        console.log('bench', bench);
        if (moduleLevel > 0) {
            let moduleFactor = 0;
            if (moduleLevel === 1) {
                moduleFactor = 0.1;
            } else if (moduleLevel === 2) {
                moduleFactor = 0.25;
            } else if (moduleLevel === 3) {
                moduleFactor = 0.40;
            } else if (moduleLevel === 4) {
                moduleFactor = 0.45;
            } else if (moduleLevel === 5) {
                moduleFactor = 0.5;
            } else {
                throw new Error('blah');
            }
            skillFactor = 1 - moduleFactor;
        }
    }
    let lavish = 1;
    if (ingredient.lavish) {
        if (skillLevel >= 6) {
            lavish = 0.95;
        }
    }
    log.push(indent(level) + 'calcAmount ' + ingredient.type + ': ' +
        ingredient.amount + '*' + factor + '*' + skillFactor + '*' + lavish + '=' +
        (ingredient.amount * factor * skillFactor * lavish).toFixed(3) +
        ' bench: ' + bench + ' module: ' + moduleLevel + ' skill: ' + skillLevel);
    return ingredient.amount * factor * skillFactor * lavish;
}

function calcCalories(level: number, cals: Calories, factor: number, log: string[], settings: Settings) {
    const skillLevel = settings.skills.find((s) => s.skill === cals.skill)?.level || 0;
    let skillFactor = 1;
    if (skillLevel > 0) {
        skillFactor = 1 - (0.5 + ((skillLevel - 1) * 0.05));
    }
    log.push(indent(level) + 'calcCalories: ' +
        cals.amount + '*' + factor.toFixed(2) + '*' + skillFactor.toFixed(2) + '=' +
        (cals.amount * factor * skillFactor).toFixed(2));
    return cals.amount * factor * skillFactor;
}

function adjustAmount(level: number, ingredient: Ingredient, factor: number, log: string[], settings: Settings, bench: string) {
    return { ...ingredient, amount: calcAmount(level, ingredient, factor, log, settings, bench) };
}

function adjustCalories(level: number, cals: Calories, factor: number, log: string[], settings: Settings) {
    return { calories: calcCalories(level, cals, factor, log, settings) };
}

interface Result {
    ingredients: any
    cals: any,
    log: string[]
};


function findProduct(p: string, lenient?: boolean) {
    const rr = getRecipes();
    let result = [];
    for (let k of Object.keys(rr)) {
        let check = (prod: Product) => prod.type === p;
        if (lenient) {
            check = (prod: Product) => prod.type.toLowerCase().indexOf(p.toLowerCase()) !== -1
        }
        if (rr[k].products.some(check)) {
            result.push(rr[k]);
        }
    }
    return result;
}

export function getRecipe(recipeName: string) {
    const rr = getRecipes();
    return rr[recipeName];
}

export function getItems(): string[] {
    const items = new Set<string>();
    const rr = getRecipes();
    for (let k of Object.keys(rr)) {
        for (let product of rr[k].products) {
            items.add(product.type);
        }
        for (let ingredient of rr[k].ingredients) {
            items.add(ingredient.type);
        }
    }

    return Array.from(items).sort();
}

export function getSkills(): string[] {
    const result = new Set<string>();
    const rr = getRecipes();
    for (let k of Object.keys(rr)) {
        for (let i of rr[k].ingredients) {
            result.add(i.skill);
        }
    }

    return Array.from(result).sort();
}
export function getBenches(): string[] {
    const benches = new Set<string>();
    const rr = getRecipes();
    for (let k of Object.keys(rr)) {
        benches.add(rr[k].bench);
    }

    return Array.from(benches).sort();
}

interface Settings {
    modules: { bench: string, level?: number }[]
    skills: { skill: string, level?: number }[]
    recipeSelections: { product: string, recipe?: string }[]
    costs: { item: string; cost?: number }[]
    tagMappings: { tag: string; product: string | undefined }[],
    single?: boolean
};


export function getIngredients(product: Recipe, productName: string, amount: number, result: Result, settings: Settings) {
    let recipeAmount = amount;
    const output = product.products.find((p) => p.type === productName);
    if (!output) {
        throw new Error('bork');
    }
    recipeAmount = amount / output.amount
    result.log.push(indent(0) + 'getIngredients ' + product.recipe + ' desired: ' + amount);
    return getIngredientsInternal(product, recipeAmount, result, 1, settings);
}

function getIngredientsInternal(product: Recipe, amount: number, result: Result, level: number, settings: Settings) {
    if (level > 10) {
        alert('Probably infinite recursion');
        throw new Error('Probably infinite recursion');
    }
    result.log.push(indent(level) + 'getIngredients ' + product.recipe + ' desired: ' + amount);
    level = level || 0;
    if (product.calories) {
        result.cals.push(adjustCalories(level, product.calories, amount, result.log, settings));
    }
    for (let i of product.ingredients) {
        //if (finalProducts.includes(i.type) || costMap[i.type]) {
        console.log('i.type', i.type)
        if (i.type.startsWith('TAG_')) {
            const mapping = settings.tagMappings.find((tm) => tm.tag === i.type.replace("TAG_", "") && tm.product);
            if (mapping && mapping.product) {
                i.type = mapping.product;
            }
        }
        const cost = settings.costs.find((c) => c.item === i.type);
        if (cost && cost.cost) {
            result.ingredients.push(adjustAmount(level, i, amount, result.log, settings, product.bench));
            continue;
        }
        result.log.push(indent(level) + 'looking for ' + i.type);
        const ps = findProduct(i.type);
        if (ps.length > 0) {
            // last one is usually most interesting
            let productRecipe = ps[ps.length - 1];
            const override = settings.recipeSelections.find((rs) => rs.product === i.type);
            if (override && override.recipe) {
                productRecipe = getRecipe(override.recipe || '');
            }
            let alts = ps.length > 1 ? ' (' + ps.length + ' alternatives)' : '';
            result.log.push(indent(level) + 'using recipe: ' + productRecipe.recipe + ' (' + productRecipe.bench + ')' + alts);
            let factor = NaN;
            for (let output of productRecipe.products) {
                if (output.type === i.type) {
                    factor = output.amount;
                    console.log('output.type', output.type, factor);
                    break;
                }
            }
            result.log.push(indent(level) + 'getIngredient ' + amount + ' ' + factor);
            let am = calcAmount(level, i, amount / factor, result.log, settings, product.bench);
            if (settings.single && level === 1) {
                result.log.push(indent(level) + `round: ${am} -> ${Math.ceil(am)}`);
                am = Math.ceil(am);
            }
            result.log.push(indent(level) + 'need: ' + am + ' ' + i.type + ' for: ' + amount + ' ' + product.recipe);
            getIngredientsInternal(productRecipe, am, result, level + 1, settings);
        } else {
            //console.log('IN ELSE');
            //, ...
            result.ingredients.push(adjustAmount(level, i, amount, result.log, settings, product.bench));
        }
    }
}
