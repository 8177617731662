import React from 'react';
import { SettingsIF, SettingsContext, recipeSelections, tags } from '../SettingsContext';
import KeyValueEditor from '../components/KeyValueEditor';

type NumberProperties<T> = Pick<T, {
    [K in keyof T]: T[K] extends number ? K : never
}[keyof T]>;

const Settings = () => {
    const settings = React.useContext(SettingsContext);
    const [tax, setTax] = React.useState('' + settings.tax);
    const [calorieCost, setCalorieCost] = React.useState('' + settings.calorieCost);
    const updateNumber = (setter: (s: string) => void, property: keyof NumberProperties<SettingsIF>) => {
        return (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value.replace(/[^0-9],.]/g, '');
            setter(value);
            const newSettings = { ...settings };
            newSettings[property] = parseFloat(value);
            settings.setSettings(newSettings);
        }
    };

    const setTaxX = updateNumber(setTax, 'tax');
    const setCalorieCostX = updateNumber(setCalorieCost, 'calorieCost');
    /* const setTaxX = (e: React.ChangeEvent<HTMLInputElement>) => {
     *     setTax(e.target.value);
     *     if (!isNaN(parseFloat(e.target.value))) {
     *         settings.setSettings({ ...settings, tax: parseFloat(e.target.value) });
     *     }
     * }
     * const setCalorieCostX = (e: React.ChangeEvent<HTMLInputElement>) => {
     *     setCalorieCost(e.target.value);
     *     if (!isNaN(parseFloat(e.target.value))) {
     *         settings.setSettings({ ...settings, calorieCost: parseFloat(e.target.value) });
     *     }
     * } */
    return (
        <>
            <div>
                Tax: <input type="text" style={{ width: '50px', height: '15px' }} value={tax} onChange={setTaxX} />%
                <br />
                <br />
            </div>
            <div>
                Calorie cost: <input type="text" style={{ width: '70px', height: '15px' }} value={calorieCost} onChange={setCalorieCostX} />
                <br />
                <br />
            </div>
            <KeyValueEditor
                possibleKeys={settings.skills.map((skill) => skill.skill)}
                getValuesForKey={(key) => [1, 2, 3, 4, 5, 6, 7].map((i) => '' + i)}
                title="Skills"
                keyLabel="Skill"
                valueLabel="Level"
                onChanged={(key, value) => {
                    const z = [...settings.skills];
                    for (let x of z) {
                        if (x.skill === key) {
                            x.level = parseInt(value, 10);
                        }
                    }
                    settings.setSettings({ ...settings, skills: z })
                }}
                onRemoved={(key) => {
                    const z = [...settings.skills];
                    for (let x of z) {
                        if (x.skill === key) {
                            x.level = 0;
                        }
                    }
                    settings.setSettings({ ...settings, skills: z })
                }}
                keyValues={settings.skills.filter((s) => (s.level || 0) > 0).map((s) => ({ key: s.skill, value: '' + (s.level || '0') }))}
            />
            <KeyValueEditor
                possibleKeys={settings.modules.map((module) => module.bench)}
                getValuesForKey={(key) => [1, 2, 3, 4, 5].map((i) => '' + i)}
                title="Modules"
                keyLabel="Module"
                valueLabel="Level"
                onChanged={(key, value) => {
                    const z = [...settings.modules];
                    for (let x of z) {
                        if (x.bench === key) {
                            x.level = parseInt(value, 10);
                        }
                    }
                    settings.setSettings({ ...settings, modules: z })
                }}
                onRemoved={(key) => {
                    const z = [...settings.modules];
                    for (let x of z) {
                        if (x.bench === key) {
                            x.level = 0;
                        }
                    }
                    settings.setSettings({ ...settings, modules: z })
                }}
                keyValues={settings.modules.filter((s) => (s.level || 0) > 0).map((s) => ({ key: s.bench, value: '' + (s.level || '0') }))}
            />
            <KeyValueEditor
                possibleKeys={settings.costs.map((cost) => cost.item)}
                getValuesForKey={() => undefined}
                title="Costs"
                keyLabel="Item"
                valueLabel="Cost"
                onChanged={(key, value) => {
                    const z = [...settings.costs];
                    for (let x of z) {
                        if (x.item === key) {
                            x.cost = parseFloat(value);
                        }
                    }
                    settings.setSettings({ ...settings, costs: z })
                }}
                onRemoved={(key) => {
                    const z = [...settings.costs];
                    for (let x of z) {
                        if (x.item === key) {
                            x.cost = 0;
                        }
                    }
                    settings.setSettings({ ...settings, costs: z })
                }}
                keyValues={settings.costs.filter((s) => (s.cost || 0) > 0).map((s) => ({ key: s.item, value: '' + (s.cost || '0') }))}
            />
            <KeyValueEditor
                possibleKeys={settings.recipeSelections.map((r) => r.product).sort()}
                getValuesForKey={(key) => recipeSelections.find((rs) => rs.product === key)?.recipes.map((r) => `${r.bench} - ${r.recipe}`)}
                title="Recipe Selections"
                keyLabel="Product"
                valueLabel="Recipe"
                onChanged={(key, value) => {
                    const z = [...settings.recipeSelections];
                    for (let x of z) {
                        if (x.product === key) {
                            x.recipe = value.split(' - ')[1];
                        }
                    }
                    settings.setSettings({ ...settings, recipeSelections: z })
                }}
                onRemoved={(key) => {
                    const z = [...settings.recipeSelections];
                    for (let x of z) {
                        if (x.product === key) {
                            delete x.recipe;
                        }
                    }
                    settings.setSettings({ ...settings, recipeSelections: z })
                }}
                keyValues={settings.recipeSelections.filter((r) => r.recipe).map((r) => ({ key: r.product, value: r.recipe || '' }))}
            />
            <KeyValueEditor
                possibleKeys={settings.tagMappings.map((r) => r.tag).sort()}
                getValuesForKey={(key) => tags[key]}
                title="Tags"
                keyLabel="Tag"
                valueLabel="Product"
                onChanged={(key, value) => {
                    const z = [...settings.tagMappings];
                    for (let x of z) {
                        if (x.tag === key) {
                            x.product = value;
                        }
                    }
                    settings.setSettings({ ...settings, tagMappings: z })
                }}
                onRemoved={(key) => {
                    const z = [...settings.tagMappings];
                    for (let x of z) {
                        if (x.tag === key) {
                            x.product = undefined;
                        }
                    }
                    settings.setSettings({ ...settings, tagMappings: z })
                }}
                keyValues={settings.tagMappings.filter((r) => r.product).map((r) => ({ key: r.tag, value: r.product || '' }))}
            />
        </>
    );
};

export default Settings;
