import React from 'react';
import { SettingsContext } from '../SettingsContext';

function SaveLoad() {
    const settings = React.useContext(SettingsContext);
    const existingSaves: any = JSON.parse(localStorage.getItem('saves') || '{}');
    const [saves, setSaves] = React.useState(existingSaves);
    const update = () => {
        localStorage.setItem('saves', JSON.stringify(saves, null, 2));
    };
    const load = (name: string) => {
        settings.setSettings({ ...settings, ...saves[name] });
    };
    const saveAs = () => {
        const name = prompt('Save name?');
        if (name) {
            const newSaves = { ...saves };
            newSaves[name] = { ...settings, setSettings: undefined };
            setSaves(newSaves);
            update();
        }
    };

    const reset = () => {
        localStorage.clear();
        window.location.reload(true);
    };
    return (
        <div>
            <button onClick={reset}>reset to default settings</button>
            <br />
            <br />
            <button onClick={saveAs}>save as</button>
            <br />
            <br />
            <div>existing saves:</div>
            {
                Object.keys(saves).map((save: string) => {
                    return <div><button onClick={() => load(save)}>load</button> {save}</div>;
                })
            }
        </div >
    );
}

export default SaveLoad;
