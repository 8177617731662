import React from 'react';
import { getRecipe, getIngredients } from '../Recipes';
import { SettingsContext } from '../SettingsContext';

function toFixed(x: number) {
    return parseFloat(x.toFixed(3))
}

function List() {
    const settings = React.useContext(SettingsContext);
    const list = settings.list;
    const results: any = [];
    for (let item of list) {
        const result: { ingredients: any, cals: any, log: string[], } = {
            ingredients: [],
            cals: [],
            log: []
        };
        getIngredients(getRecipe(item.recipe), item.product, 1, result, settings);
        const xyz: any = {};
        xyz.product = item.product;
        const finalIngredients = result.ingredients.reduce((acc: any, ingredient: any) => {
            if (acc[ingredient.type]) {
                acc[ingredient.type].amount += ingredient.amount;
            } else {
                acc[ingredient.type] = ingredient;
            }
            return acc;
        }, {});

        const costMap = settings.costs.reduce((acc: any, cost) => {
            if (cost.cost) {
                acc[cost.item] = cost.cost;
            }
            return acc;
        }, {});

        const calories = result.cals.reduce((acc: any, cals: any) => acc + cals.calories, 0);

        /* result.log.push('\n'); */
        //result.log.push('\n');
        //result.log.push('SKILLS')
        //console.table(skillLevels);
        /* result.log.push('\n');
         * result.log.push(props.recipeName);
         * result.log.push('=====');
         * result.log.push('total ingredients: '); */
        const ingredientsTable: any = {};
        for (let type of Object.keys(finalIngredients)) {
            const i = finalIngredients[type];
            ingredientsTable[i.type] = { amount: toFixed(i.amount), cost: costMap[type] || '?' };
            //`${i.amount.toFixed(2)} ($${costMap[type] || '?'})`;
            /* result.log.push(`${i.type}: ${i.amount.toFixed(2)} ($${costMap[type] || '?'})`); */
        }
        //console.table(ingredientsTable);

        /* result.log.push('\ncalories: ' + calories.toFixed(2));
         */
        /* result.log.push('\n');
         * result.log.push('=====');
         * result.log.push('costs: '); */
        const unaccounted: any = [];
        const costs = Object.keys(finalIngredients).reduce((acc, k) => {
            if (costMap[k]) {
                acc += costMap[k] * finalIngredients[k].amount;
            } else {
                unaccounted.push(k);
            }
            return acc;
        }, 0);

        const calorieCost = settings.calorieCost;
        /* const totalCost =
         *     ((costs * (1 + (tax / 100.0))) + (calories * calorieCost)) * (1 + (markup / 100.0)); */
        //result.log.push('calories: $' + (calories * calorieCost).toFixed(2));
        //result.log.push('raw cost: $' + costs.toFixed(2));
        /* result.log.push('adjusted cost (tax, markup): $' + (totalCost * (1 + (tax / 100.0) + (markup / 100.0))).toFixed(3)); */
        if (unaccounted.length > 0) {
            /* result.log.push('unaccounted: ' + unaccounted.join(', ')); */
        }
        xyz.materialCost = costs;
        xyz.calorieCost = calories * calorieCost;
        xyz.breakEven = costs + calories * calorieCost;
        xyz.breakEvenTax = (costs + calories * calorieCost) * (1 + (settings.tax / 100.0));
        xyz.tenTax = (costs + calories * calorieCost) * (1 + (settings.tax / 100.0) + 0.10);
        xyz.twentyTax = (costs + calories * calorieCost) * (1 + (settings.tax / 100.0) + 0.20);
        xyz.fiftyTax = (costs + calories * calorieCost) * (1 + (settings.tax / 100.0) + 0.50);
        xyz.unaccounted = unaccounted.join(', ');
        xyz.recipe = item.recipe;
        results.push(xyz);
    }
    if (list.length === 0) {
        return <div>Add items from the "Craft" page</div>;
    }
    const remove = (index: number) => {
        const newList = [...list];
        newList.splice(index, 1);
        console.log('newList', index, newList);
        settings.setSettings({ ...settings, list: newList });
    };
    return (
        <table className="table" style={{ width: '95%' }}>
            <thead>
                <tr>
                    <th>Product</th>
                    <th>Materials</th>
                    <th>Calories</th>
                    <th>Break-even</th>
                    <th>Break-even + tax</th>
                    <th>10% profit</th>
                    <th>20% profit</th>
                    <th>50% profit</th>
                    <th>Unaccounted</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {results.map((result: any, index: number) => {
                    return (
                        <tr key={index} style={{ backgroundColor: result.unaccounted.length > 0 ? 'yellow' : undefined }}>
                            <td>{result.product} ({result.recipe})</td>
                            <td style={{ textAlign: 'right' }}>${result.materialCost.toFixed(3)}</td>
                            <td style={{ textAlign: 'right' }}>${result.calorieCost.toFixed(3)}</td>
                            <td style={{ textAlign: 'right' }}>${result.breakEven.toFixed(3)}</td>
                            <td style={{ textAlign: 'right' }}>${result.breakEvenTax.toFixed(3)}</td>
                            <td style={{ textAlign: 'right' }}>${result.tenTax.toFixed(3)}</td>
                            <td style={{ textAlign: 'right' }}>${result.twentyTax.toFixed(3)}</td>
                            <td style={{ textAlign: 'right' }}>${result.fiftyTax.toFixed(3)}</td>
                            <td style={{ textAlign: 'right' }}>{result.unaccounted}</td>
                            <td style={{ textAlign: 'center' }}><button onClick={() => remove(index)}>Remove</button></td>
                        </tr>
                    );
                })}
            </tbody>
        </table >
    );
}

export default List;
