import React from 'react';
import { getFoods, Food } from '../Recipes';

const nutrients = ['carbs', 'protein', 'fat', 'vitamins']

function calculate(foods: Food[]) {
    const nutsums = nutrients.map((nutrient: string) => {
        const n = nutrient as 'carbs' | 'protein' | 'fat' | 'vitamins';
        return foods.reduce((acc: number, food: Food) => acc + food.nutrients[n] * food.calories, 0);
    });
    const calories = foods.reduce((acc, food) => acc + food.calories, 0);
    const avgs = nutsums.map((ns) => {
        return ns / calories
    });
    const totavgs = avgs.reduce((acc, avg) => acc + avg, 0);
    const balance = totavgs / (Math.max(...avgs) * 4) * 2;

    const tot = foods.reduce((acc, food) => {
        const nuts = nutrients.reduce((acc, nutrient) => {
            const n = nutrient as 'carbs' | 'protein' | 'fat' | 'vitamins';
            return acc + food.nutrients[n]
        }, 0);
        return acc + (nuts * food.calories);
    }, 0);
    const v = tot / foods.reduce((acc, food) => acc + food.calories, 0);

    const base = 12;
    const r = v * balance + base;
    return {
        foods,
        sp: r,
        value: v,
        balance: balance
    };
}

function _combinations<T>(chosen: number[], arr: T[], index: number, r: number, start: number, end: number, res: T[][]) {
    if (index === r) {
        let result: T[] = [];
        for (let i = 0; i < r; i++) {
            result.push(arr[chosen[i]]);
        }
        res.push(result);
        return;
    }

    for (let i = start; i <= end; i++) {
        chosen[index] = i;
        _combinations(chosen, arr, index + 1, r, i, end, res);
    }
}

function combinations<T>(arr: T[], n: number, r: number) {
    const chosen: number[] = [];
    const res: T[][] = [];
    _combinations(chosen, arr, 0, r, 0, n - 1, res);
    return res;
}

interface Entry {
    sp: string;
    foodValue: string;
    balance: string;
    foods: string;
}

type Entries = Entry[];

function getCalculated(desiredNum: number, foodsList: Food[]) {
    const x = foodsList.map((food) => food.name);
    const foodNames = combinations<string>(x, x.length, desiredNum);
    const results = [];
    for (let i = 0; i < foodNames.length; i++) {
        const toTest = [];
        for (let name of foodNames[i]) {
            for (let f of foodsList) {
                if (f.name === name) {
                    toTest.push(f);
                }
            }
        }

        const result = calculate(toTest);
        results.push(result);
    }
    const mapped = results.sort((r1, r2) => r2.sp - r1.sp).slice(0, 10).map((r) => {
        return {
            sp: r.sp.toFixed(2),
            foodValue: r.value.toFixed(2),
            balance: r.balance.toFixed(2),
            foods: r.foods.map((f) => f.name.replace('Item', '').replace(/([A-Z])/g, ' $1')).sort().join(', ')
        }
    });
    return mapped;
}

function FoodTable(props: { entries: Entry[] }) {
    const entries = props.entries;
    const list = entries.map((entry: Entry) => (
        <tr key={entry.sp + '_' + entry.foods}>
            <td style={{ width: '10%' }}>{entry.sp}</td>
            <td style={{ width: '10%' }}>{entry.foodValue}</td>
            <td style={{ width: '10%' }}>{entry.balance}</td>
            <td>{entry.foods}</td>
        </tr>
    ));
    return (
        <table className="table" style={{ width: '95%' }}>
            <thead>
                <tr>
                    <th style={{ width: '10%' }}>sp</th>
                    <th style={{ width: '10%' }}>foodValue</th>
                    <th style={{ width: '10%' }}>balance</th>
                    <th>foods</th>
                </tr>
            </thead>
            <tbody>
                {list}
            </tbody>
        </table>
    );
}

class FoodLists extends React.PureComponent<{ ignored: string }> {
    render() {
        const { ignored } = this.props;
        const foods = getFoods().filter((food) => {
            return food.name &&
                !ignored
                    .split(',')
                    .map((item: string) => item.trim())
                    .filter((item: string) => item.length > 0)
                    .map((item: string) => item + 'Item')
                    .includes(food.name)
        });
        const bySkill = (skill: string) => foods.filter((food) => food.requiredSkill && food.requiredSkill.skill && [skill].includes(food.requiredSkill.skill));
        const noSkill = foods.filter((food) => (!food.requiredSkill || food.requiredSkill.level === '0'));
        return (
            <>
                <h2>No Skill</h2>
                <FoodTable entries={getCalculated(2, noSkill)} />
                <FoodTable entries={getCalculated(3, noSkill)} />
                <h2>Campfire Cooking</h2>
                <FoodTable entries={getCalculated(2, bySkill('CampfireCookingSkill'))} />
                <FoodTable entries={getCalculated(3, bySkill('CampfireCookingSkill'))} />
                <h2>Baking</h2>
                <FoodTable entries={getCalculated(2, bySkill('BakingSkill'))} />
                <FoodTable entries={getCalculated(3, bySkill('BakingSkill'))} />
                <h2>Cooking</h2>
                <FoodTable entries={getCalculated(2, bySkill('CookingSkill'))} />
                <FoodTable entries={getCalculated(3, bySkill('CookingSkill'))} />
                <h2>Advanced Baking</h2>
                <FoodTable entries={getCalculated(2, bySkill('AdvancedBakingSkill'))} />
                <FoodTable entries={getCalculated(3, bySkill('AdvancedBakingSkill'))} />
                <h2>Advanced Cooking</h2>
                <FoodTable entries={getCalculated(2, bySkill('AdvancedCookingSkill'))} />
                <FoodTable entries={getCalculated(3, bySkill('AdvancedCookingSkill'))} />
            </>
        )
    }
}

function FoodComponent() {
    const defaultIgnored = 'Ecoylent, CampfireStew, FriedVegetables, CampfireSalad, Milk';
    const [ignoredItemsText, setIgnoredItemsText] = React.useState<string>(defaultIgnored);
    const [ignoredItemsState, setIgnoredItemsState] = React.useState<string>(defaultIgnored);

    return (
        <>
            <div>
                Ignored items:
                <input type="text" style={{ width: '550px', height: '15px' }} value={ignoredItemsText} onChange={(e) => setIgnoredItemsText(e.target.value)} />
                <button onClick={(e) => setIgnoredItemsState(ignoredItemsText)}> Apply</button>
            </div>
            <FoodLists ignored={ignoredItemsState} />
        </>
    );
}

export default FoodComponent;
