import React from 'react';
import { getRecipe, getIngredients } from '../Recipes';
import { SettingsContext } from '../SettingsContext';
interface RecipeProps {
    recipeName: string,
    productName: string,
    single: boolean
}

function toFixed(x: number) {
    return parseFloat(x.toFixed(3))
}

const Recipe: React.SFC<RecipeProps> = (props: RecipeProps) => {
    const settings = React.useContext(SettingsContext);
    console.log(settings.skills[0]);
    const result: { ingredients: any, cals: any, log: string[], } = {
        ingredients: [],
        cals: [],
        log: []
    };
    getIngredients(getRecipe(props.recipeName), props.productName, 1, result, { ...settings, single: props.single });
    const costMap = settings.costs.reduce((acc: any, cost) => {
        if (cost.cost) {
            acc[cost.item] = cost.cost;
        }
        return acc;
    }, {});

    const finalIngredients = result.ingredients.reduce((acc: any, ingredient: any) => {
        if (acc[ingredient.type]) {
            acc[ingredient.type].amount += ingredient.amount;
        } else {
            acc[ingredient.type] = ingredient;
        }
        return acc;
    }, {});

    const calories = result.cals.reduce((acc: any, cals: any) => acc + cals.calories, 0);

    result.log.push('\n');
    //result.log.push('\n');
    //result.log.push('SKILLS')
    //console.table(skillLevels);
    result.log.push('\n');
    result.log.push(props.recipeName);
    result.log.push('=====');
    result.log.push('total ingredients: ');
    const ingredientsTable: any = {};
    for (let type of Object.keys(finalIngredients)) {
        const i = finalIngredients[type];
        ingredientsTable[i.type] = { amount: toFixed(i.amount), cost: costMap[type] || '?' };
        //`${i.amount.toFixed(2)} ($${costMap[type] || '?'})`;
        result.log.push(`${i.type}: ${i.amount.toFixed(2)} ($${costMap[type] || '?'})`);
    }
    //console.table(ingredientsTable);

    result.log.push('\ncalories: ' + calories.toFixed(2));

    result.log.push('\n');
    result.log.push('=====');
    result.log.push('costs: ');
    const unaccounted: any = [];
    const costs = Object.keys(finalIngredients).reduce((acc, k) => {
        if (costMap[k]) {
            acc += costMap[k] * finalIngredients[k].amount;
        } else {
            unaccounted.push(k);
        }
        return acc;
    }, 0);

    const tax = settings.tax;
    const calorieCost = settings.calorieCost;
    const totalCost =
        ((costs * (1 + (tax / 100.0))) + (calories * calorieCost));
    result.log.push('calories: $' + (calories * calorieCost).toFixed(2) + ' (' + calories + '*' + calorieCost + ')');
    result.log.push('material cost: $' + costs.toFixed(2));
    result.log.push('break even: $' + totalCost.toFixed(3));
    result.log.push('adjusted cost (tax): $' + totalCost.toFixed(3));
    if (unaccounted.length > 0) {
        result.log.push('unaccounted: ' + unaccounted.join(', '));
    }

    return (
        <div>
            <pre>{JSON.stringify(result.ingredients, null, 2)}</pre>
            <pre>{result.log.join('\n')}</pre>
        </div>
    );
}

export default Recipe;
