import React from 'react';
import { getItems, getBenches, getSkills, getTags, getRecipeSelections, getDataHash } from './Recipes';

export const tags = getTags();

export const recipeSelections = getRecipeSelections();

const skills = getSkills();
const benches = getBenches();
const items: string[] = getItems();

export interface SettingsIF {
    skills: {
        skill: string;
        level?: number;
    }[],
    modules: {
        bench: string;
        level?: number;
    }[],
    list: {
        product: string;
        recipe: string;
    }[],
    tax: number,
    costs: {
        item: string;
        cost?: number;
    }[],
    calorieCost: number,
    recipeSelections: {
        product: string;
        recipe: string | undefined;
    }[],
    tagMappings: {
        tag: string;
        product: string | undefined;
    }[],
    hash: number
};
const defaultSettings: SettingsIF = {
    skills: skills.map((skill) => ({
        skill: skill,
        level: undefined
    })),
    modules: benches.map((bench) => ({
        bench: bench,
        level: undefined
    })),
    list: [],
    tax: 0,
    costs: items.map((item) => ({
        item: item,
        cost: undefined
    })),
    calorieCost: 0,
    recipeSelections: recipeSelections.map((rs) => ({
        product: rs.product,
        recipe: undefined
    })),
    tagMappings: Object.keys(tags).map((tag) => ({
        tag: tag,
        product: undefined
    })),
    hash: getDataHash()
};

const SettingsContext = React.createContext({ ...defaultSettings, setSettings: (_x: SettingsIF) => { } });

export { SettingsContext, defaultSettings, skills, benches };
