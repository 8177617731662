import React from 'react';
import ProductSelector from '../components/ProductSelector';
/* import RecipeSelector from '../components/RecipeSelector'; */
import Recipe from '../components/Recipe';
import { Link } from 'react-router-dom';
import { getRecipesForProduct } from '../Recipes';
import {
    useRouteMatch,
    useParams,
    useHistory,
    Switch,
    Route,
} from "react-router-dom";
import { SettingsContext } from '../SettingsContext';

function Craft() {
    const match = useRouteMatch();
    const params: any = useParams();
    const history = useHistory();
    const settings = React.useContext(SettingsContext);
    const [single, setSingle] = React.useState(false);

    // TODO: How to fix urls?
    const onProductSelected = (p: string) => history.push(`/craft/${p}`);
    const addToList = () => {
        settings.setSettings({ ...settings, list: [...settings.list, { product: params.product, recipe: params.recipe }] });
    };
    let recipes: string[] | undefined = undefined;
    if (params.product) {
        recipes = getRecipesForProduct(params.product).map((r) => r.recipe);
    }
    const RecipeSelector = (props: { recipes?: string[] }) => {
        if (!props.recipes || props.recipes.length === 0) {
            return <></>;
        } else {
            return (
                <ul>
                    {props.recipes.map((recipe) =>
                        <li key={recipe}><Link to={`/craft/${params.product}/${recipe}`}>{recipe}</Link></li>
                    )}
                </ul>
            );
        }
    };

    const updateSingle = (e: any) => {
        setSingle(e.target.checked);
    };
    return (
        <Switch>
            <Route path={`${match.path}/:product/:recipe`}>
                <div>
                    <ProductSelector product={params.product} onSelected={onProductSelected} />
                    <RecipeSelector recipes={recipes} />
                    <input name="single" type="checkbox" checked={single} onChange={updateSingle} />Single?
                    <Recipe productName={params.product} recipeName={params.recipe} single={single} />
                    <button onClick={addToList}>add to list</button>
                </div>
            </Route>
            <Route path={`${match.path}/:product`}>
                <div>
                    <ProductSelector product={params.product} onSelected={onProductSelected} />
                    <RecipeSelector recipes={recipes} />
                </div>
            </Route>
            <Route path={match.path}>
                <div>
                    <ProductSelector onSelected={onProductSelected} />
                </div>
            </Route>
        </Switch>
    );
}

export default Craft;
